import React from "react";
import Texts from "../../../utils/lang-utils";
import background from '../../../areas/main/assets/images/background/banner-2-bg.jpg';
import "./Features.scss";
import "./Foodstagram.scss";
import foodstagramImage from "../../../areas/main/assets/images/resources/eatella-foodstagram-app.jpg";
import urls from "../../../areas/main/assets/urls";

const OtherServices = () => {
    return (
        <section className="service-one d-flex align-items-center features foodstagram" style={{ backgroundImage: `url('${background}')` }}>
            <div className="container text-center ">

                <div className="row">

                <div className="col-md-6 mobile-hide" style={{ marginTop: 'auto', marginBottom: 'auto' }}>

<img src={foodstagramImage} alt="alter text" style={{
      maxWidth: '100%',
      borderRadius: 20,
      boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
}} />
</div>

                    <div className=" col-md-6 text-left">
                    <div className="block-title">
                      <h2 className="block-title__title light" dangerouslySetInnerHTML={{ __html: Texts.QROrdering.FoodstagramTitle }} />
                      <p className="block-title__text subtitle" dangerouslySetInnerHTML={{ __html: Texts.QROrdering.FoodstagramSubtitle }} />
                      <img src={foodstagramImage} alt="alter text" className="mobile-show" style={{
                        display: 'none',
                        margin: 'auto',
      maxWidth: '100%',
      borderRadius: 20,
      boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
}} />
                  </div>
                  <div >
                <h3><i className="fa fa-check"></i> {Texts.QROrdering.FoodstagramItem1Title}</h3>
                <h3><i className="fa fa-check"></i> {Texts.QROrdering.FoodstagramItem2Title}</h3>
                <h3><i className="fa fa-check"></i> {Texts.QROrdering.FoodstagramItem3Title}</h3>
                <a href={urls.onboarding} className="thm-btn mt-3 mobile-hide">
                  <span>{Texts.GetStarted}</span>
                </a>
              </div>
                    </div>

                </div>
            </div>
        </section>
    );
};
export default OtherServices;
